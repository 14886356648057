$black: #101820;
$green: #2cc84d;
$lightgreen: #58df73;
$blue: #4ca6e2;
$darkblue: #1681c9;
$lightblue: #7ebde8;
$mazarine: #1a202c;
$orange: #ffa400;
$lightorange: #f7ba51;
$grey: #88898a;
$lightgrey: #f5f5f9;
