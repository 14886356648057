@import "/src/styles/variables.scss";
html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-display: swap;
}

.ant-input {
  margin: 0px 10px 10px 10px !important;
  height: 40px;
  border-radius: 10px;
  text-align: left !important;
  box-shadow: none !important;
  &::placeholder {
    font-size: 16px;
  }
  &:focus {
    border-color: $blue;
  }
}

.ant-btn-info {
  background-color: $orange;
  border-color: $orange;
  color: white !important;
}
.ant-btn-info:hover {
  background-color: $lightorange;
  border-color: $lightorange;
}
.ant-btn-info:focus {
  background-color: $orange;
  border-color: $orange;
}

.ant-btn {
  font-size: 18px;
  padding: 20px;
  color: white;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: fade;
  transition: none !important;
  &:hover {
    font-weight: bold;
  }
}

.ant-btn-primary {
  background-color: $blue;
  border-color: $blue;
  &:hover {
    font-weight: normal;
    background-color: $lightblue !important;
  }
}

.ant-btn-success {
  background-color: $green !important;
  border-color: $green !important;
  &:hover {
    background-color: $lightgreen !important;
    border-color: $lightgreen !important;
  }
}

.filters {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;

  .ant-btn {
    font-size: 25px;
    color: #516170;
    height: 45px;
    margin-right: 30px;
    border-radius: 10px;
    background: #4ca6e2;
    color: white;
    display: flex;
    justify-content: center;
    &:hover {
      font-weight: normal !important;
      background: $lightblue;
    }
    &.selected {
      background: $darkblue;
    }
  }
}

.ant-drawer {
  &:not(.ant-drawer-bottom) {
    .ant-drawer-content-wrapper {
      width: 60% !important;
    }
  }
}
.ant-drawer-body {
  overflow: hidden;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.ant-drawer-body .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-drawer-body .ant-btn-primary {
  width: 90%;
  font-size: 14px;
  margin-top: 10px;
}
.ant-drawer-body .ant-btn-secondary {
  width: 90%;
  font-size: 14px;
  border: 1px solid lightgrey;
  color: black;
}
.am-list-item .am-list-content .ant-btn {
  height: 50px;
  width: 100%;
  color: black !important;
  border-radius: 0px;
}
.am-list-item .am-list-content .logoImage {
  width: 250px !important;
}
.ant-select-item-option-content img {
  width: 100px;
}
.testOutilFormModal {
  width: 1000px !important;
}
.investissementPopover {
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border: 1px solid $orange;
    width: 300px;
  }
  &.investissementCapital {
    .ant-popover-inner {
      width: 600px;
      > div > div {
        display: flex;
        > div {
          flex: 1;
        }
      }
      @media (max-width: 1024px) {
        width: 300px;
        > div > div {
          flex-direction: column;
        }
      }
    }
  }
}
.investissementFilter {
  .ant-popover-inner {
    border: 1px solid $blue;
    border-radius: 10px;
    width: 300px;
    .ant-popover-inner-content > div {
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .ant-input-number-group-wrapper {
          border: 1px solid $blue;
          border-radius: 10px;
          .ant-input-number {
            width: 70px;
            border: none;
            border-right: 1px solid $blue;
            border-radius: 10px 0px 0px 10px;
          }
          .ant-input-number-group-addon {
            border: none;
            border-radius: 0px 10px 10px 0px;
          }
        }
        .ant-btn-link {
          color: $blue;
          text-decoration: underline;
          padding: 0px;
        }
      }
    }
  }
}
.signInModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
      }
      > p {
        font-weight: bold;
        font-size: 24px;
      }
      .ant-form-item-label {
        display: none;
      }
      .ant-form-item-control {
        max-width: 100% !important;
        margin: 0px !important;
      }
      .ant-input {
        border: 1px solid $orange;
        border-radius: 20px;
        height: 50px;
        margin: 0px !important;
      }
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-btn-secondary {
        color: $orange;
        text-decoration: underline;
        text-shadow: none;
        box-shadow: none;
        padding: 0px;
        &:hover {
          font-style: italic;
          font-weight: normal;
        }
      }
      .ant-btn-success {
        background-color: $orange !important;
        width: 100%;
        border-radius: 20px;
        height: 50px;
        font-weight: bold;
        color: white !important;
        &:hover {
          background-color: $lightorange !important;
          font-style: italic;
          color: white !important;
        }
      }
      .ant-btn-primary {
        background: transparent;
        color: $orange;
        border: none;
        text-shadow: none;
        box-shadow: none;
        padding: 0px;
        a {
          text-decoration: underline;
        }
        &:hover {
          background: transparent !important;
          font-style: italic;
        }
      }
      @media (max-width: 1024px) {
        .ant-form {
          width: 100%;
        }
        .ant-btn-secondary,
        .ant-btn-primary {
          font-size: 16px;
        }
      }
    }
  }
}
/*code CSS du loader*/
#__next,
.loading_spinner {
  width: 100%;
  height: 100%;
}
.loading_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-spin-dot-item:nth-child(1) {
  background: $grey;
}
.ant-spin-dot-item:nth-child(2) {
  background: $orange;
}
.ant-spin-dot-item:nth-child(3) {
  background: $green;
}
.ant-spin-dot-item:nth-child(4) {
  background: $blue;
}
